/* eslint-disable prettier/prettier */
import { KeyStatus, PinCode } from "../models/PinCodeData";
import styles from "../styles/pinCodeCardStyles.module.css";
import { formatPinCodeDate } from "../utils/dateUtils";
import { getKeyStatusText } from "../utils/livionKeyUtils";

interface PinCodeCardProps {
  pinCodeData?: PinCode;
  keyStatusData?: KeyStatus[];
}

export const PinCodeCard = (props: PinCodeCardProps) => {
  const pinCodeData = props.pinCodeData ?? {
    pinCode: "Not set",
    createdAt: "-",
    lockSystem: "",
    updatedAt: "-",
    validFrom: "-",
    validTo: "-",
    primary: true,
  };

  const getSMSOpeningText = () => {
    return (
      <div>
        <p>SMS Opening:</p>
        <p>&nbsp;&nbsp;Number: {pinCodeData.smsOpening?.phoneNumber}</p>
        <p>
          &nbsp;&nbsp;Message: {pinCodeData.smsOpening?.prefix}
          {pinCodeData.pinCode}
        </p>
      </div>
    );
  };

  return (
    <div className={styles.datacardTexts}>
      {pinCodeData.lockSystem && pinCodeData.primary && (
        <p>Primary Key System: {pinCodeData.lockSystem}</p>
      )}
      {pinCodeData.lockSystem && !pinCodeData.primary && (
        <p>Secondary Key System: {pinCodeData.lockSystem}</p>
      )}
      <p>PIN: {`${pinCodeData.pinCode}#`}</p>
      <p>PIN Created: {formatPinCodeDate(pinCodeData.createdAt)}</p>
      <p>PIN Valid From: {formatPinCodeDate(pinCodeData.validFrom)}</p>
      <p>PIN Valid To: {formatPinCodeDate(pinCodeData.validTo)}</p>
      {pinCodeData.lastUsed && (
        <p>PIN Last Used: {formatPinCodeDate(pinCodeData.lastUsed)}</p>
      )}
      {pinCodeData.firstUsed && (
        <p>Arrival Time: {formatPinCodeDate(pinCodeData.firstUsed)}</p>
      )}
      {pinCodeData.lockSystem === "LivionKey" && (
        <p>Key Status: {getKeyStatusText(props.keyStatusData)}</p>
      )}
      {pinCodeData.padUrl && (
        <p>
          Pad Url:{" "}
          <a href={pinCodeData.padUrl} target="_blank" rel="noreferrer">
            {pinCodeData.padUrl}
          </a>{" "}
        </p>
      )}
      {pinCodeData.smsOpening && getSMSOpeningText()}
    </div>
  );
};
